import * as React from "react";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { PositionType, UitkMenu, UitkMenuContainer, UitkMenuList, UitkMenuTrigger } from "@egds/react-core/menu";
import { UitkLink } from "@egds/react-core/link";
import { LocalizedText } from "bernie-l10n";
import { Action, sendImmediateTrackEvent } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { inject } from "mobx-react";
import { CrossLOBMenuProps } from "../typings";

const moduleName = "crossLobLink";
export const CrossLOBMenu = inject("analytics")((props: CrossLOBMenuProps) => {
  const [isCrossLOBMenuOpen, setCrossLOBMenuOpen] = React.useState(false);
  const [position, setPosition] = React.useState(PositionType.RIGHT);

  const onCrossLOBMenuTriggerClick = () => {
    setCrossLOBMenuOpen(!isCrossLOBMenuOpen);
  };

  const keyHelper = new ItemKeyHelper("breadcrumbs-menu");
  const menuItems = props.items
    .filter((item) => item.hasOwnProperty("url"))
    .map((item) => {
      const trackingInfo = {
        moduleName,
        action: Action.CLICK,
        rfrr: item.posType,
      };

      return {
        attributes: {
          role: "menuItem",
        },
        className: "uitk-list-item",
        href: item.url,
        label: item.text,
        typeAnchor: true,
        key: keyHelper.next(),
        onClick: React.useCallback(() => {
          sendImmediateTrackEvent(trackingInfo, props.analyticsStore);
        }, [trackingInfo, props.analyticsStore]),
      };
    });

  /* istanbul ignore next */
  const setMenuPosition = React.useCallback(
    (buttonClicked: HTMLSpanElement) => {
      const isLeftPosition = buttonClicked.getBoundingClientRect().left === 12;
      const newPosition = isLeftPosition ? PositionType.LEFT : PositionType.RIGHT;

      if (newPosition !== position) {
        setPosition(newPosition);
      }
    },
    [position, setPosition]
  );

  const onBtnClick = React.useCallback(
    (e) => {
      const span = e.target;

      setMenuPosition(span);

      const flexTracking = {
        moduleName,
        action: Action.CLICK,
        rfrr: "menu",
      };
      sendImmediateTrackEvent(flexTracking, props.analyticsStore);
    },
    [setMenuPosition]
  );

  return (
    <UitkMenu className="breadcrumbs-dropdown" isOpen={isCrossLOBMenuOpen} onTriggerClick={onCrossLOBMenuTriggerClick}>
      <UitkMenuTrigger>
        <UitkLink onClick={onBtnClick} size="small">
          <button>
            <LocalizedText message="breadcrumbs.title.planYourTrip" />
          </button>
        </UitkLink>
      </UitkMenuTrigger>
      <UitkMenuContainer position={position}>
        <UitkMenuList items={menuItems} />
      </UitkMenuContainer>
    </UitkMenu>
  );
});

export default CrossLOBMenu;
